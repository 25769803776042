<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
              <div class="text-center">
                <h5 class="card-title"><i class="ri-edit-line"></i> {{ $t('externalUserIrrigation.pump_operator_application_form') }}</h5>
              </div>
          </template>
          <template v-slot:body>
              <profile-status-msg :preConditionLoader="preConditionLoader" :profileStatus="profileStatus" :profile-url="'/irrigation/my-profile'" :inForm="true"/>
              <b-overlay :show="submitload">
                <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                  <b-form @submit.prevent="handleSubmit(register)" @reset.prevent="reset">
                      <div @click="displayProfileCheckAlert">
                    <b-row>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Organization" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="org_id"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{$t('org_pro.organization')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select

                                  v-model="pumpOperatorApp.org_id"
                                  :options="orgList"
                                  id="org_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                            <ValidationProvider name="Name" vid='name' rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    :label="$t('user.name')"
                                    label-for="name"
                                    slot-scope="{ valid, errors }"
                                >
                                    <b-form-input
                                    id="name"
                                    readonly
                                    v-model="pumpOperatorApp.name"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                            <ValidationProvider name="Name (bn)" vid='name_bn' rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    :label="$t('user.name_bn')"
                                    label-for="name_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <b-form-input
                                    id="name_bn"
                                    readonly
                                    v-model="pumpOperatorApp.name_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                          </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="4" sm="12">
                            <ValidationProvider name="NID" vid='nid' rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    :label="$t('externalUserIrrigation.nid')"
                                    label-for="nid"
                                    slot-scope="{ valid, errors }"
                                >
                                    <b-form-input
                                    type="number"
                                    id="nid"
                                    readonly
                                    :value="pumpOperatorApp.nid"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Father name" vid='father_name' rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                :label="$t('externalUserIrrigation.father_namef')"
                                label-for="father_name"
                                slot-scope="{ valid, errors }"
                            >
                                <b-form-input
                                id="father_name"
                                readonly
                                v-model="pumpOperatorApp.father_name"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                            <ValidationProvider name="Father name (bn)" vid='father_name_bn' rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    :label="$t('externalUserIrrigation.father_name_bn')"
                                    label-for="father_name_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <b-form-input
                                    id="father_name_bn"
                                    readonly
                                    v-model="pumpOperatorApp.father_name_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="4" sm="12">
                            <ValidationProvider name="Mother name" vid='mother_name' rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    :label="$t('externalUserIrrigation.mother_namef')"
                                    label-for="mother_name"
                                    slot-scope="{ valid, errors }"
                                >
                                    <b-form-input
                                    id="mother_name"
                                    readonly
                                    v-model="pumpOperatorApp.mother_name"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                            <ValidationProvider name="Mother name (bn)" vid='mother_name_bn' rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    :label="$t('externalUserIrrigation.mother_name_bn')"
                                    label-for="mother_name_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <b-form-input
                                    id="mother_name_bn"
                                    readonly
                                    v-model="pumpOperatorApp.mother_name_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row class="mb-4">
                        <b-col lg="6" sm="12">
                            <b-form-checkbox
                                class="mt-1"
                                id="same_as_personal_address"
                                v-model="same_as_personal_address"
                                :value="true"
                                :unchecked-value="false"
                            >
                                {{ $t('externalUserIrrigation.same_as_farmer_info') }}
                            </b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Division" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                :label="$t('org_pro_division.division')"
                                label-for="far_division_id"
                                slot-scope="{ valid, errors }"
                                >
                                <b-form-select
                                :disabled="same_as_personal_address"
                                v-model="addressHolder.far_division_id"
                                :options="divisionList"
                                id="far_division_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                            <ValidationProvider name="District" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    :label="$t('org_pro_district.district')"
                                    label-for="far_district_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <b-form-select
                                    :disabled="same_as_personal_address"
                                    v-model="addressHolder.far_district_id"
                                    :options="districtList"
                                    id="far_district_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                            <ValidationProvider name="Upazila" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    :label="$t('org_pro_upazilla.upazilla')"
                                    label-for="far_upazila_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <b-form-select
                                    :disabled="same_as_personal_address"
                                    v-model="addressHolder.far_upazilla_id"
                                    :options="upazilaList"
                                    id="far_upazila_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="4" sm="12">
                            <ValidationProvider name="Union" rules="required|min_value:1">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                :label="$t('org_pro_union.union')"
                                label-for="far_union_id"
                                slot-scope="{ valid, errors }"
                                >
                                <b-form-select
                                :disabled="same_as_personal_address"
                                v-model="addressHolder.far_union_id"
                                :options="unionList"
                                id="far_union_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Pump ID" vid='pump_id' rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="pump_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                    {{$t('externalUserIrrigation.pump') + ' ' + $t('globalTrans.id')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select

                                    v-model="pumpOperatorApp.pump_id"
                                    @change="pumpOperatorApp.pump_id = $event"
                                    :options="pumpList"
                                    id="pump_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                            <ValidationProvider name="Office" vid='office_id' rules="required">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="office_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                  {{ $t('warehouseFarmer.office')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                id="office_id"
                                v-model="pumpOperatorApp.office_id"
                                :options="officeList"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="4" sm="12">
                            <ValidationProvider name="Village" vid='far_village' rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    :label="$t('externalUserIrrigation.villagef')"
                                    label-for="far_village"
                                    slot-scope="{ valid, errors }"
                                >
                                    <b-form-input
                                    id="far_village"
                                    readonly
                                    v-model="pumpOperatorApp.far_village"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                            <ValidationProvider name="Village (bn)" vid='far_village_bn' rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    :label="$t('externalUserIrrigation.village_bn')"
                                    label-for="far_village_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                <b-form-input
                                    id="far_village_bn"
                                    readonly
                                    v-model="pumpOperatorApp.far_village_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                            <ValidationProvider name="Date of Birth" vid='date_of_birth' rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="date_of_birth"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('externalUserIrrigation.date_of_birth')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    type="date"
                                    id="date_of_birth"
                                    v-model="pumpOperatorApp.date_of_birth"
                                    placeholder="Date of Birth"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="4" sm="12">
                            <ValidationProvider name="Mobile" vid='far_mobile_no' rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="far_mobile_no"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('user.phone_no')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    type="number"
                                    id="far_mobile_no"
                                    v-model="pumpOperatorApp.far_mobile_no"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                            <ValidationProvider name="Gender" vid='gender' rules="required">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                :label="$t('externalUserIrrigation.gender')"
                                label-for="gender"
                                slot-scope="{ valid, errors }"
                                >
                                <b-form-select

                                    v-model="pumpOperatorApp.gender"
                                    :options="genderList"
                                    id="gender"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                         <b-col lg="4" sm="12">
                            <ValidationProvider name="Qualification" vid='qualification'>
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                :label="$t('externalUserIrrigation.educational_qualification')"
                                label-for="qualification"
                                slot-scope="{ valid, errors }"
                                >
                                <b-form-input
                                id="qualification"
                                v-model="pumpOperatorApp.qualification"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="4" sm="12">
                            <ValidationProvider name="Applicant Photo" vid='applicant_photo'>
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    :label="$t('externalUserIrrigation.applicant_photo')"
                                    label-for="applicant_photo"
                                    slot-scope="{ valid, errors }"
                                >
                                <b-form-file
                                    @change="onFileApplicantPhotoChange"
                                    v-model="pumpOperatorApp.applicant_photo"
                                    id="applicant_photo"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                </b-form-file>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12" v-if="id">
                            <ValidationProvider name="Current Photo">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="current_photo"
                                    :label="$t('externalUserIrrigation.current_photo')"
                                >
                                    <img :src="baseUrl + 'download-attachment?file=uploads/pump-operator-application/applicant-photo/original/' + pumpOperatorApp.applicant_photo" class="height-150 width-150 img-fluid" style="border:2px solid"/>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <div>
                        <b-row>
                            <b-col sm="12" class="my-2">
                                <h5 class="ex_form_title">{{ $t('externalUserIrrigation.document') + ' ' + $t('externalUserIrrigation.information') }}</h5>
                            </b-col>
                        </b-row>
                        <b-row style="border-bottom:1px solid #ddd; margin-bottom:10px">
                            <b-col lg="3">
                              <p class="text-dark">{{ $t('externalUserIrrigation.document_title_en') }}</p>
                            </b-col>
                            <b-col lg="3">
                                <p class="text-dark">{{ $t('externalUserIrrigation.document_title_bn') }}</p>
                            </b-col>
                            <b-col lg="3">
                                <p class="text-dark">{{ $t('externalUserIrrigation.attachment') }} (Max size: 2048 KB)</p>
                            </b-col>
                            <b-col lg="3">
                                <p class="text-dark">{{ $t('globalTrans.action') }}</p>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg='3' sm='6'>
                                <ValidationProvider name="Document Title(En)" vid='document_title' rules="">
                                    <b-form-group
                                        label-for="document_title"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <b-form-input
                                            id="document_title"
                                            v-model="document.document_title"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg='3' sm='6'>
                                <ValidationProvider name="Document Title (Bn)" vid='document_title_bn' rules="required_if:document_title">
                                    <b-form-group
                                        label-for="document_title_bn"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <b-form-input
                                            id="document_title_bn"
                                            v-model="document.document_title_bn"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg='3' sm='6'>
                                <ValidationProvider name="attachment" rules="required_if:document_title_bn">
                                    <b-form-group
                                        label-for="attachment"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <b-form-file
                                            v-on:change="onFileChange"
                                            v-model="document.attachment"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-file>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg='3' sm='6'>
                                <b-form-group>
                                    <b-button class="btn btn-outline-success mr-2" variant=" iq-bg-success" size="sm" @click="saveDocument">{{ $t('globalTrans.add') }}</b-button>
                                    <b-button v-if="totalDocument > 0" class="btn-sm">Total Added : {{ totalDocument }}</b-button>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row v-for="(tmpDoc,index) in documents" :key="index">
                            <b-col lg="4">
                                <p class="text-dark">{{ tmpDoc.document_title }}</p>
                            </b-col>
                            <b-col lg="4">
                                <p class="text-dark">{{ tmpDoc.document_title_bn }}</p>
                            </b-col>
                            <b-col lg="2">
                                <a :href="baseUrl + 'download-attachment?file=uploads/pump-operator-document/original/' + tmpDoc.attachment" title="Download"><i class="ri-download-cloud-fill"></i></a>
                            </b-col>
                            <b-col lg="2">
                                <b-button class="btn btn-outline-danger" variant=" iq-bg-danger" size="sm" @click="deleteAttachment(tmpDoc.id, index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                            </b-col>
                        </b-row>
                    </div>
                      </div>
                    <b-row class="d-flex justify-content-center">
                        <b-col>
                            <div class="mt-3 text-center">
                                <button class="btn btn-primary ml-3" type="submit">{{ saveBtnName }}</button>
                            </div>
                        </b-col>
                    </b-row>
                  </b-form>
                </ValidationObserver>
              </b-overlay>
          </template>
        </iq-card>
        <b-modal id="modal-1" size="lg" :title="$t('externalUserIrrigation.payment')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <p>
            <Payment />
          </p>
        </b-modal>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import ProfileStatusMsg from '@/modules/external-user-service/irrigation/components/ProfileStatusMsg'
import { Address } from '@/mixins/helper-functions'
import {
    pumpOptApplicationStore,
    pumpOptApplicationUpdate,
    pumpOptApplicationDocument,
    pumpOptApplicationDocumentStore,
    pumpOptApplicationDocumentDestroy,
    farmerInfo,
    allPumpInfoList
} from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
    name: 'PumpOptApp',
    mixins: [Address],
    components: {
        ValidationObserver,
        ValidationProvider,
        ProfileStatusMsg
    },
    data () {
        return {
            preConditionLoader: false,
            profileStatus: 0,
            submitload: false,
            statePrefix: 'ExternalUserIrrigation.commonObj', // Required to get store sates for addresses
            id: this.$route.params.id,
            loadingState: true,
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('externalUserIrrigation.save_application'),
            addressHolder: {
                far_division_id: 0,
                far_district_id: 0,
                far_upazilla_id: 0,
                far_union_id: 0
            },
            pumpOperatorApp: {
                org_id: 0,
                pump_id: 0,
                email: '',
                name: '',
                name_bn: '',
                gender: 0,
                father_name: '',
                father_name_bn: '',
                mother_name: '',
                mother_name_bn: '',
                nid: '',
                far_mobile_no: '',
                far_division_id: 0,
                far_district_id: 0,
                far_upazilla_id: 0,
                far_union_id: 0,
                office_id: 0,
                far_village: '',
                far_village_bn: '',
                date_of_birth: '',
                qualification: '',
                final_approve: 0,
                status: 0,
                payment_status: 0,
                master_payment_id: '',
                applicant_photo: []
            },
            same_as_personal_address: false,
            document: {
                user_id: '',
                document_title: '',
                document_title_bn: '',
                attachment: []
            },
            upload_attachment: '',
            genList: [
                {
                    value: 1,
                    text_en: 'Male',
                    text_bn: 'পুরুষ'
                },
                {
                    value: 2,
                    text_en: 'Female',
                    text_bn: 'মহিলা'
                }
            ],
            documents: [],
            isDocument: false,
            totalDocument: 0,
            districtList: [],
            upazilaList: [],
            unionList: [],
            baseUrl: irriSchemeServiceBaseUrl,
            officeList: []
        }
    },
    async created () {
        this.checkUser()
        this.submitload = true
        this.getFarmerData()
        .then(() => {
            this.submitload = false
            if (this.id) {
                const tmp = this.getPumpOptApplicationData()
                this.pumpOperatorApp = tmp
                this.addressHolder.far_division_id = this.pumpOperatorApp.far_division_id
                this.addressHolder.far_district_id = this.pumpOperatorApp.far_district_id
                this.addressHolder.far_upazilla_id = this.pumpOperatorApp.far_upazilla_id
                this.addressHolder.far_union_id = this.pumpOperatorApp.far_union_id
                this.getDocuments(this.id)
                Object.assign({}, this.pumpOperatorApp, this.getFarmerDetails)
            } else {
                Object.assign(this.pumpOperatorApp, this.getFarmerDetails)
            }
        })
        this.pumpOperatorApp.far_mobile_no = this.$store.state.Auth.authUser.mobile_no

        // organization selected when url from portal
        if (this.$route.query.org_id && parseInt(this.$route.query.org_id) === 15) {
            this.pumpOperatorApp.org_id = 15
        }
    },
    mounted () {
        flatpickr('#date_of_birth', {})
        core.index()
    },
    computed: {
        getFarmerDetails () {
            return this.$store.state.ExternalUserIrrigation.farmerBasicInfo
        },
        orgList () {
            return this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList
        },
        pumpList () {
            return this.$store.state.ExternalUserIrrigation.pumpList
        },
        genderList () {
            const data = this.genList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
            return data
        },
        divisionList () {
            return this.$store.state.ExternalUserIrrigation.commonObj.divisionList
        },
        currentLocale () {
            return this.$i18n.locale
        }
    },
    watch: {
        currentLocale: function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.genderList()
            }
        },
        'addressHolder.far_division_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.districtList = this.getDistrictList(newVal)
                this.getPumpInfo()
            }
        },
        'addressHolder.far_district_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.upazilaList = this.getUpazilaList(newVal)
                this.getPumpInfo()
            }
        },
        'addressHolder.far_upazilla_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.unionList = this.getUnionList(newVal)
                this.getPumpInfo()
                this.officeList = this.getOfficeList(newVal)
            }
        },
        same_as_personal_address: function (newVal, oldVal) {
            if (newVal !== oldVal) {
                if (newVal) {
                    this.addressHolder = Object.freeze(this.pumpOperatorApp)
                } else {
                    this.addressHolder = {
                        far_division_id: 0,
                        far_district_id: 0,
                        far_upazilla_id: 0,
                        far_union_id: 0
                    }
                }
            }
        }
    },
    methods: {
        checkUser () {
            this.preConditionLoader = true
            RestApi.getData(irriSchemeServiceBaseUrl, farmerInfo + '?with_data=' + false).then(response => {
                if (response.success) {
                    this.profileStatus = response.data.status === 2 ? 4 : 0
                }
                this.preConditionLoader = false
            })
        },
        getPumpInfo () {
            RestApi.getData(irriSchemeServiceBaseUrl, allPumpInfoList, {
                paginate: false,
                division_id: this.addressHolder.far_division_id,
                district_id: this.addressHolder.far_district_id,
                upazilla_id: this.addressHolder.far_upazilla_id
            })
            .then((res) => {
                const formatted = res.data.map((el) => {
                    return {
                        pump_id: el.pump_id,
                        value: el.id,
                        text: el.pump_id,
                        text_en: el.pump_id,
                        text_bn: this.$n(el.pump_id, { useGrouping: false })
                    }
                })
                this.$store.dispatch('ExternalUserIrrigation/getPumpList', formatted)
            })
        },
        getPumpOptApplicationData () {
            return this.$store.state.ExternalUserIrrigation.pumpOptApplicationLists.find(item => item.id === parseInt(this.id))
        },
        onFileApplicantPhotoChange (e) {
            this.applicant_photo = e.target.files[0]
        },
        async register () {
            if (this.profileStatus === 0) {
                this.displayProfileCheckAlert()
                return
            }
            let result = ''
            this.submitload = true
            this.pumpOperatorApp.far_division_id = this.addressHolder.far_division_id
            this.pumpOperatorApp.far_district_id = this.addressHolder.far_district_id
            this.pumpOperatorApp.far_upazilla_id = this.addressHolder.far_upazilla_id
            this.pumpOperatorApp.far_union_id = this.addressHolder.far_union_id

            var formData = new FormData()
            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }
            Object.keys(this.pumpOperatorApp).map(key => {
                formData.append(key, this.pumpOperatorApp[key])
            })

            if (this.id) {
                if (this.pumpOperatorApp.status === 4) {
                    this.updateStatus()
                    result = await RestApi.postData(irriSchemeServiceBaseUrl, pumpOptApplicationStore, formData, config)
                } else {
                    formData.append('_method', 'PUT')
                    result = await RestApi.postData(irriSchemeServiceBaseUrl, `${pumpOptApplicationUpdate}/${this.id}`, formData, config)
                }
            } else {
                result = await RestApi.postData(irriSchemeServiceBaseUrl, pumpOptApplicationStore, formData, config)
            }
            this.submitload = false
            if (result.success) {
                // const notification = result.notification
                // this.$socket.emit('send-notification', notification)
                this.$toast.success({
                    title: 'Success',
                    message: result.message
                })
                setTimeout(async () => {
                    this.$router.push('/irrigation/pump-operator-application')
                }, 500)
            } else {
                this.$refs.form.setErrors(result.errors)
                this.$toast.error({
                    title: 'Error',
                    message: 'Operation failed! Please, try again.'
                })
            }
        },
        async updateStatus () {
            await RestApi.postData(irriSchemeServiceBaseUrl, '/farmer-pump-operator-application/reject/' + this.id).then(response => {})
        },
        getDocuments (applicationId) {
            RestApi.getData(irriSchemeServiceBaseUrl, pumpOptApplicationDocument + '/' + applicationId).then(response => {
                if (response.success) {
                    this.isDocument = true
                    this.documents = response.data.length > 0 ? response.data : []
                }
            })
        },
        getOfficeList (upazilaId = null) {
            const officeList = this.$store.state.ExternalUserIrrigation.commonObj.officeList.filter(office => office.district_id === this.addressHolder.far_district_id && office.status === 0)
            if (upazilaId) {
                const tmpOfficeList = officeList.filter(item => item.upazilla_id === upazilaId && item.status === 0)
                if (tmpOfficeList.length > 0) {
                    return tmpOfficeList
                }
            }
            return officeList
        },
        onFileChange (e) {
            this.upload_attachment = e.target.files[0]
        },
        async saveDocument () {
            var formData = new FormData()
            Object.keys(this.document).map(key => {
                if (key === 'attachment') {
                    formData.append(key, this.upload_attachment)
                } else {
                    formData.append(key, this.document[key])
                }
            })
            formData.append('user_id', this.pumpOperatorApp.farmer_id)
            const result = await RestApi.postData(irriSchemeServiceBaseUrl, pumpOptApplicationDocumentStore, formData, {
                headers: { 'content-type': 'multipart/form-data' }
            })
            if (result.success) {
                this.document.document_title = this.document.document_title_bn = ''
                this.document.attachment = []
                this.totalDocument += 1
                this.$toast.success({
                    title: 'Success',
                    message: this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })
            } else {
                this.$refs.form.setErrors(result.errors)
                this.$toast.error({
                    title: 'Error',
                    message: 'Operation failed! Please, try again.'
                })
            }
        },
        deleteAttachment (documentId, index) {
            RestApi.deleteData(irriSchemeServiceBaseUrl, pumpOptApplicationDocumentDestroy + '/' + documentId).then(response => {
                if (response.success) {
                    this.documents.splice(index, 1)
                    this.$toast.success({
                        title: 'Success',
                        message: 'Document remove successfully',
                        color: '#D6E09B'
                    })
                }
            })
        },
        getDistrictList (divisionId = null) {
            return this.$store.state.ExternalUserIrrigation.commonObj.districtList
            .filter(item => item.division_id === divisionId && item.status === 0)
        },
        getUpazilaList (districtId = null) {
            return this.$store.state.ExternalUserIrrigation.commonObj.upazilaList
            .filter(item => item.district_id === districtId && item.status === 0)
        },
        getUnionList (upazilaId = null) {
            return this.$store.state.ExternalUserIrrigation.commonObj.unionList
            .filter(item => item.upazilla_id === upazilaId && item.status === 0)
        },
        async getFarmerData () {
            return await RestApi.getData(irriSchemeServiceBaseUrl, farmerInfo).then(async (response) => {
                if (response.success) {
                    this.$store.dispatch('ExternalUserIrrigation/getFarmerBasicInfoDetails', response.data)
                }
            })
        },
        displayProfileCheckAlert () {
          if (this.profileStatus !== 0) {
              return
          }
          // const msg = this.$t('externalPanel.profile_complete_msg_part1') + '<a href="/irrigation/my-profile">links</a> ' + this.$t('externalPanel.profile_complete_msg_part2')
          this.$swal({
              title: this.$t('externalPanel.profile_complete_msg'),
              showCloseButton: true,
              confirmButtonText: 'Close',
              // showCancelButton: true,
              // html: msg
              focusConfirm: false
          })
        }
    }
}
</script>
